import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import React from "react";
import Label from "../../atoms/Label/Label";
import {
  TableRowAtom,
  TableRowProps,
} from "../../molecules/TableRow/TableRowAtom";
import { useStyles, LabelWrapper, Wrapper } from "./TableOrganismStyles";

export interface TableOrganismProps {
  header: TableRowProps;
  rows: Array<TableRowProps>;
  tableClassName?: string;
}

const TableOrganism: React.FC<TableOrganismProps> = (
  props: TableOrganismProps
) => {
  const { rows, header, tableClassName } = props;

  const classes = useStyles(props);

  const loadHeaderRow = () => {
    return <TableRowAtom cells={header.cells} />;
  };

  const loadRows = () => {
    return rows.map((row, index) => {
      return <TableRowAtom key={index} cells={row.cells} />;
    });
  };

  return (
    <Wrapper>
      <TableContainer component={Paper} elevation={0}>
        <Table className={`${classes.table} ${tableClassName}`}>
          {rows.length !== 0 ? (
            <>
              <TableHead className={classes.tableBody}>{loadHeaderRow()}</TableHead>
              <TableBody className={classes.tableBody} component={Paper}>{loadRows()}</TableBody>
            </>
          ) : (
            <LabelWrapper>
              <Label label="There are no Records Yet ." className="label" />
            </LabelWrapper>
          )}
        </Table>
      </TableContainer>
    </Wrapper>
  );
};
export default TableOrganism;
