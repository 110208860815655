import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import Loader from "../../atoms/Loader/LoaderAtom";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import ReturnTypeButtons from "../../molecules/returnTypeButtons/ReturnTypeButtons";
import { largeButton } from "../../utilities/style";
import useApiService from "../../utilities/useApiService";
import {
  Container,
  FormMoleculeWrapper,
  HeaderWrapper,
} from "./CreateReturnPageStyle";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { createChooseWholesalerForm, createSelectDateForm } from "./FormData";
import { useAuthState } from "../../context/AuthContext";
import { isAdmin } from "../../utilities/checkUserRole";
import { TextBox } from "../../atoms/Text Box/TextBox";
import { ReturnRequestServiceType } from "../../utilities/Enums";

interface ICreateReturnRequestPayload {
  serviceType: ReturnRequestServiceType;
  preferredDate?: string;
}

export interface CreateReturnPageProps {}

const CreateReturnPage: React.FC<CreateReturnPageProps> = (
  props: CreateReturnPageProps
) => {
  const { handleSubmit, control } = useForm();
  const { postMethod } = useApiService();
  const params: any = useParams();
  const history = useHistory();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();

  const [serviceType, setServiceType] =
    useState<ReturnRequestServiceType | undefined>(undefined);

  const createReturnRequestApi = async (data: ICreateReturnRequestPayload) => {
    startLoading(loaderDispatch);
    const response = await postMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests`,
      data
    );
    stopLoading(loaderDispatch);
    return response.data;
  };
  const onSuccessfulCreation = (returnRequest: any) => {
      history.push(`/pharmacies/${returnRequest.pharmacy.id}/returns`);
  };
  const { mutate: createReturnRequest } = useMutation(createReturnRequestApi, {
    onSuccess: onSuccessfulCreation,
  });

  const isServiceTypeExpress = () => {
    return serviceType === ReturnRequestServiceType.ExpressService;
  };

  const handleClick = handleSubmit((data: FieldValues) => {
    if (isServiceTypeExpress()) {
      history.push(`/pharmacies/${params.pharmacyId}/returnrequests/add`);
  } else {
      const fullData: ICreateReturnRequestPayload = prepareDataForReturnRequestCreation(data);
      createReturnRequest(fullData);
  }
  });

  const prepareDataForReturnRequestCreation = (data: FieldValues) => {
    let fullData: ICreateReturnRequestPayload = { serviceType: serviceType! };
    if (!isServiceTypeExpress()) {
      fullData.preferredDate = data.preferredDate;
    }
    return fullData;
  };

  const renderReturnTypeButtons = () => {
    return (
      <>
        <HeaderWrapper>
          <TextBox
            title="What type of service do you need?"
            alignTitle="center"
            titleColor="#A61C14"
            headerFontSize={28}
          />
        </HeaderWrapper>
        <ReturnTypeButtons
          value={serviceType}
          onClick={(value: ReturnRequestServiceType) => {
            setServiceType(value);
          }}
        />
      </>
    );
  };

  const renderFormSectionAndButton = () => {
    if (serviceType) {
      return (
        <>
          {renderDateField()}
          <CallToActionButton
            height={"80px"}
            text={renderButtonTextValue()}
            type="submit"
            width={largeButton.width}
            onClick={handleClick}
          />
        </>
      );
    }
  };

  const renderDateField = () => {
    if (!isServiceTypeExpress()) {
      return (
        <>
          <HeaderWrapper>
            <TextBox
              title={"Select your preferred date"}
              alignTitle="center"
              titleColor="#A61C14"
              headerFontSize={28}
            />
          </HeaderWrapper>
          <FormMolecule control={control} list={createSelectDateForm()} />
        </>
      );
    }
  };

  const renderButtonTextValue = () => {
    if (isServiceTypeExpress()) {
      return "Proceed To Add Items";
    }
    return "Create";
  };

  return loading ? (
    <Loader />
  ) : (
    <Container>
      {renderReturnTypeButtons()}
      {renderFormSectionAndButton()}
    </Container>
  );
};

export default CreateReturnPage;
