import { Step, StepIconProps, StepLabel, Typography, Link } from "@material-ui/core";
import { Stepper } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import {
  useStyles,
  FormContainer,
  TermsWrapper,
  ColorlibConnector,
  useColorlibStepIconStyles,
  Container,
  ButtonWrapper,
  ButtonContainer,
  ProceedWrapper,
  SmallBlueButtonWrapper
} from "./CreateAccountStyles";
import { default as BusinessInfo } from "../../assets/BusinessInfo.svg";
import { default as ContactInfo } from "../../assets/ContactInfo.svg";
import { default as PrimaryInfo } from "../../assets/Primary.svg";
import { default as Referral } from "../../assets/Referred.svg";
import { default as BusinessInfoActive } from "../../assets/BusinessInfoActive.svg";
import { default as ContactInfoActive } from "../../assets/ContactInfoActive.svg";
import { default as PrimaryInfoActive } from "../../assets/PrimaryActive.svg";
import { default as ReferralActive } from "../../assets/ReferredActive.svg";
import clsx from "clsx";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { useForm, Controller, useFormContext, useFieldArray, FormProvider } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import {
  step1,
  contactInfo1,
  contactInfo2,
  contactInfo3,
  step4,
  directDeposit,
  getWholesalerForm
} from "./FormData";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { createPharmacy } from "../../network/Services";
import { useQuery, useMutation } from "react-query";
import { ICreatePharmacy, ICreatePharmacyFullInfo, IPharmacyWholesaler, IWholesalerInfo } from "../../network/models/PharmacyInterfaces";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { useLoaderDispatch } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";
import { default as Terms } from "../../assets/TermsConditions.pdf";
import useApiService from "../../utilities/useApiService";
import { IWholesaler } from './../../network/models/Interfaces';
import CheckboxListMolecule from "../../molecules/CheckboxList/CheckboxListMolecule";

import { SmallBlueButtonAtom } from "../../atoms/SmallBlueButton/SmallBlueButtonAtom";
import AccordionAtom from "../../atoms/Accordion/AccordionAtom";
import { setTextRange } from "typescript";
import AreYouSure from "../../molecules/AreYouSure/AreYouSure";
import { isArray } from "util";
import { render } from "@testing-library/react";
import { SelectOption } from './../../utilities/SelectOptions';
import { isUserRoleNotEqual } from "../../utilities/checkUserRole";
import { UserRole } from "../../utilities/Enums";
import { useAuthState } from "../../context/AuthContext";

export interface CreateAccountProps { }

export const CreateAccount: React.FC<CreateAccountProps> = (
  props: CreateAccountProps
) => {
  let formRef: any = useRef();
  let history = useHistory();
  const params: any = useParams();
  const { handleSubmit, watch, formState: { errors }, control, getValues, setValue } = useForm();
  let reimbursementType = watch("pharmacy.reimbursementType");
  const currentUser = JSON.parse(localStorage.getItem("currentUser")!);
  const currentUserId = currentUser.id;
  const [accepted, setAccepted] = useState(false);
  const [sameAddresses, setSameAddresses] = useState(false);
  const [wholesalerSelectOptions, setWholesalerSelectOptions] = useState<Array<SelectOption>>([]);
  const [pharmacyWholesalers, setPharmacyWholesalers] = useState<Array<IPharmacyWholesaler>>([]);
  const { getMethod } = useApiService();
  const [addAdditional, setAddAdditional] = useState<Array<JSX.Element>>([]);
  const [countAdditional, setCountAdditional] = useState<number>(0);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [toBeDeletedObject, setToBeDeletedObject] = useState<{ name: string }>({ name: '' });
  const [indexToBeDeleted, setIndexToBeDeleted] = useState<Array<number>>([]);
  const [renderDeleteCount, setRenderDeleteCount] = useState<number>(0);
  const [primaryHasError, setPrimaryHasError] = useState<number>(0);
  const [openErrors, setOpenErrors] = React.useState<boolean>(true);
  const [errorArr, setErrorArr] = React.useState<number>(1);
  const { user } = useAuthState();

  const getWholesalers = async () => {
    let response;
    response = await getMethod('/wholesalers');
    return response.data;
  }
  const onSuccessfulWholesalersFetching = (wholesalers: Array<IWholesaler>) => {
    const selectOptions = wholesalers.map(({ id, name }: IWholesaler) => {
      return {
        value: id.toString(),
        label: name
      }
    })
    setWholesalerSelectOptions(selectOptions);
  }
  const { data } = useQuery('wholesalers', getWholesalers, {
    onSuccess: onSuccessfulWholesalersFetching
  });

  const handleAdditionalWholesaler = () => {
    let wholeSaler = getValues().wholeSalerInfo as IWholesalerInfo;
    let infoId = Object.values(wholeSaler.id)
    let infoAddress = Object.values(wholeSaler.address)
    let infoCity = Object.values(wholeSaler.city)
    let infoState = Object.values(wholeSaler.state)
    let infoZip = Object.values(wholeSaler.zip)

    indexToBeDeleted.sort(function (a, b) { return b - a });
    indexToBeDeleted.map((index) => {
      infoId.splice(index, 1)
      infoAddress.splice(index, 1)
      infoCity.splice(index, 1)
      infoState.splice(index, 1)
      infoZip.splice(index, 1)
    })

    let infoPrimary = new Array(infoId.length).fill(false)
    infoPrimary[0] = true

    let wholesalersPayload: Array<IPharmacyWholesaler> = [];
    for(let i = 0; i < infoId.length; i++) {
      let wholesaler: IPharmacyWholesaler = {
        wholesalerId: parseInt(infoId[i]),
        address: infoAddress[i],
        city: infoCity[i],
        state: infoState[i],
        zipCode: infoZip[i],
        primary: infoPrimary[i]
      }
      wholesalersPayload.push(wholesaler)
    }
    setPharmacyWholesalers(wholesalersPayload)
  }

  useEffect(() => {
    if (countAdditional > addAdditional.length - 1 && countAdditional != 0) {
      const newAdditionalWholesaler: JSX.Element = (
        <AccordionAtom
          key={`AdditionalWholeSaler${countAdditional}`}
          name={`AdditionalWholeSaler${countAdditional}`}
          id={`AdditionalWholeSaler${countAdditional}`}
          title={"Additional WholeSaler"}
          subTitle={""}
          onDelete={(text) => handleDeleteClick(text)}
          deleteIcon={true}
          body={
            <FormMolecule
              key={`AdditionalWholeSaler${countAdditional}`}
              control={control}
              list={getWholesalerForm(countAdditional, wholesalerSelectOptions)}
            />
          }
        />
      )
      setAddAdditional(addAdditional.concat(newAdditionalWholesaler))
    }
  }, [countAdditional])

  useEffect(() => {
    const AccordionName = toBeDeletedObject.name;
    const replaced = AccordionName.replace(/\D/g, '');
    let index = parseInt(replaced);
    if (!isNaN(index))
      setIndexToBeDeleted([...indexToBeDeleted, index])
    setAddAdditional(addAdditional.filter(function (Accordion) {
      return Accordion.props.name !== AccordionName;
    }));
  }, [renderDeleteCount])

  const handleWholesalerForms = () => {
    setCountAdditional(countAdditional + 1)
  }

  const handleDeleteClick = (text: string) => {
    setOpenConfirmDelete(true);
    setToBeDeletedObject({ name: text });
  }

  const renderAreYouSureDialog = () => {
    return (
      <AreYouSure
        open={openConfirmDelete}
        onClose={() => {
          setOpenConfirmDelete(false);
        }}
        toBeDeletedObject={toBeDeletedObject}
        deleteObject={handleDelete}
      />
    )
  }
  const handleDelete = () => {
    setRenderDeleteCount(renderDeleteCount + 1);
  }

  var res: number[] = [];

  useEffect(() => {
    if (!openErrors) {
      errors.wholeSalerInfo && Object.entries(errors.wholeSalerInfo).map(function (key, index) {
        key.map((keyy, index) => {
          if (isArray(keyy)) {
            keyy.map((x, index) => {
              res.indexOf(index) === -1 && res.push(index);

            })
          }
        })
      }
      )
    }
    res.sort()
    indexToBeDeleted.sort((f, s) => s - f);
    let x: any = []
    if (indexToBeDeleted) {
      indexToBeDeleted.map(function (keyDel) {
        res.map(function (keyRes, index) {
          if (keyRes >= keyDel) {
            for (let i: number = index; i < res.length; i++) {
              return res[i]--
            }
          }
        })

      })
    }
    handleOpeningAccordion()
  }, [errors.wholeSalerInfo, errorArr])

  const handleOpeningAccordion = () => {
    setOpenErrors(false)
    const x = addAdditional.map((n, index) => {
      if (res.findIndex(i => i == index + 1) != -1)
        return { ...n, props: { ...n.props, hasError: errorArr } }
      else return { ...n, props: { ...n.props, hasError: 0 } }

    })
    setAddAdditional(x)
    setPrimaryHasError(res.findIndex(index => index === 0) === -1 ? 0 : errorArr)
  }

  const getStepContent = (stepIndex: number) => {
    return (
      <>
        {stepIndex === 0 && (
          <FormContainer>
            <FormMolecule control={control} list={step1} disableAccountNumber={isUserRoleNotEqual(user?.role, UserRole.Admin)} />
            <FormLabel component="legend">Reimbursement Type</FormLabel>
            <Controller
              control={control}
              name={"pharmacy.reimbursementType"}
              defaultValue="CHEQUE_REQUEST"
              render={({ field, fieldState: { error } }) => (
                <RadioGroup aria-label="Reimbursement Type" {...field}>
                  <FormControlLabel
                    value="CHEQUE_REQUEST"
                    control={<Radio />}
                    label="Check Request"
                  />
                  <FormControlLabel
                    value="DIRECT_DEPOSIT"
                    control={<Radio />}
                    label="Direct Deposit"
                  />
                </RadioGroup>
              )}
            ></Controller>
            {reimbursementType === "DIRECT_DEPOSIT" && (
              <FormMolecule control={control} list={directDeposit} />
            )}
          </FormContainer>
        )}
        {stepIndex === 1 && (
          <FormContainer>
            <FormMolecule label="Basic" control={control} list={contactInfo1} />
            <FormMolecule
              label="Address"
              control={control}
              list={contactInfo2}
            />
            <div className={classes.sameAddressCheck}>
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={() => setSameAddresses(!sameAddresses)}
                checked={sameAddresses}
              />
              <Typography>
                Mailing address same as physical address
              </Typography>
            </div>
            {!sameAddresses && (
              <FormMolecule
                label="Mailing Address"
                control={control}
                list={contactInfo3}
              />
            )}
          </FormContainer>
        )}
        {stepIndex === 2 && (
          <FormContainer>
            <AccordionAtom
              name={"Primary WholeSaler"}
              hasError={primaryHasError}
              title={"Primary WholeSaler"}
              subTitle={""}
              body={<FormMolecule control={control} list={getWholesalerForm(0, wholesalerSelectOptions)} />}
            />
            {addAdditional}
            <SmallBlueButtonWrapper>
              <SmallBlueButtonAtom onClick={handleWholesalerForms} text={"Add Additional Wholesaler"} />
            </SmallBlueButtonWrapper>
            {openConfirmDelete && renderAreYouSureDialog()}
          </FormContainer>
        )}
        {stepIndex === 3 && (
          <FormContainer>
            <FormMolecule control={control} list={step4} />
            <TermsWrapper>
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={() => setAccepted(!accepted)}
                checked={accepted}
              />
              <Typography>
                I have read and agreed to the{" "}
                <Link href={Terms} target="_blank" className={classes.link} underline="always">Terms & Conditions</Link>{" "}
                {/* and{" "}
                <Link href="https://www.google.com/" target="_blank" className={classes.link} underline="always">Service Agreement</Link> */}
              </Typography>
            </TermsWrapper>
          </FormContainer>
        )}
      </>
    );
  };

  const classes = useStyles(props);

  const steps = [
    "Business Info",
    "Contact Info",
    "Wholesalers",
    "Referred By",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [stepsClickable, setStepsClickable] = useState([
    true,
    false,
    false,
    false,
  ]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const steps = [...stepsClickable];
    steps[activeStep + 1] = true;
    setStepsClickable(steps);
  };

  const handleStepperClick = (index: number) => {
    setActiveStep(index);
  };
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderDispatch();

  const { mutate } = useMutation(
    () => {
      let pharmacyFullInfoValues = getValues() as ICreatePharmacyFullInfo;
      pharmacyFullInfoValues = {
        pharmacy: {
          ...pharmacyFullInfoValues.pharmacy,
          wholesalersLinks: pharmacyWholesalers
        },
        pharmacyCompanyAddressInfo: {
          ...pharmacyFullInfoValues.pharmacyCompanyAddressInfo
        },
        pharmacyContactInfo: {
          ...pharmacyFullInfoValues.pharmacyContactInfo
        },
        pharmacyMailingAddressInfo: {
          ...pharmacyFullInfoValues.pharmacyMailingAddressInfo
        }
      }
      // delete pharmacyFullInfoValues.wholeSalerInfo
      if (sameAddresses) {
        pharmacyFullInfoValues = {
          pharmacy: {
            ...pharmacyFullInfoValues.pharmacy
          },
          pharmacyCompanyAddressInfo: {
            ...pharmacyFullInfoValues.pharmacyCompanyAddressInfo
          },
          pharmacyContactInfo: {
            ...pharmacyFullInfoValues.pharmacyContactInfo
          },
          pharmacyMailingAddressInfo: {
            address1: pharmacyFullInfoValues.pharmacyCompanyAddressInfo.address1,
            address2: pharmacyFullInfoValues.pharmacyCompanyAddressInfo.address2,
            city: pharmacyFullInfoValues.pharmacyCompanyAddressInfo.city,
            state: pharmacyFullInfoValues.pharmacyCompanyAddressInfo.state,
            zip: pharmacyFullInfoValues.pharmacyCompanyAddressInfo.zip,
          }
        }
      }

      if (history.location.pathname.indexOf("admin") !== -1) {
        return createPharmacy(
          pharmacyFullInfoValues,
          loaderDispatch,
          params.userId
        )
      } else {
        return createPharmacy(
          pharmacyFullInfoValues, 
          loaderDispatch,
          currentUserId
        ) 
      }
    },
    {
      onSuccess: (data) => {
        history.location.pathname.indexOf("admin") !== -1
          ? history.push(`/admin/pharmacies/${data.pharmacy.id}`)
          : history.push(`/pharmacies/${data.pharmacy.id}`);
      },
    }
  );
  const onSubmit = handleSubmit(async () => {
    if (activeStep === 2)
      handleAdditionalWholesaler();
    if (activeStep === 3) {
      mutate();
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
      handleNext();
    }

  });

  function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <img src={BusinessInfo} alt="" />,
      2: <img src={ContactInfo} alt="" />,
      3: <img src={PrimaryInfo} alt="" />,
      4: <img src={Referral} alt="" />,
      5: <img src={BusinessInfoActive} alt="" />,
      6: <img src={ContactInfoActive} alt="" />,
      7: <img src={PrimaryInfoActive} alt="" />,
      8: <img src={ReferralActive} alt="" />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {active || completed
          ? icons[String(Number(props.icon) + 4)]
          : icons[String(props.icon)]}
      </div>
    );
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <Stepper
        ref={formRef}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {window.innerWidth > 700 ? (
          steps.map((label, index) => (
            <Step
              onClick={() => {
                stepsClickable[index] && handleStepperClick(index);
              }}
              key={label}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))
        ) : (
          <Step onClick={() => { }} key={steps[activeStep]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {steps[activeStep]}
            </StepLabel>
          </Step>
        )}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>
            <form>{getStepContent(activeStep)}</form>
          </Typography>
          <ProceedWrapper>
            <BigRedButton
              disabled={!accepted && activeStep === 3}
              text={activeStep === steps.length - 1 ? "Submit" : "Proceed"}
              version={ButtonVersion.LARGE}
              type=""
              onClick={() => { setErrorArr(errorArr + 1); onSubmit() }}
            />
          </ProceedWrapper>
          <ButtonContainer>
            <ButtonWrapper>
              <TextButton
                disabled={activeStep === 0}
                text={"Back"}
                version={TextButtonVersion.PRIMARY}
                onClick={() => setActiveStep(activeStep - 1)}
              ></TextButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <BigRedButton
                version={ButtonVersion.SMALL}
                text={activeStep === steps.length - 1 ? "Submit" : "Proceed"}
                type=""
                onClick={onSubmit}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </div>
      </div>
    </Container>
  );
};