import { Box, Paper } from "@material-ui/core";
import React from "react";
import { UnderlinedInputFieldProps } from "../../atoms/UnderlinedInputField/UnderlinedInputField";
import { PharmacyInfoTag } from "../../molecules/pharmacyInfo/PharmacyInfoTag";
import { UnderlinedInputFieldSection } from "../../molecules/UnderlinedInputFieldSection/UnderlinedInputFieldSection";
import { useStyles } from "./PharmacyInfoSectionStyle";
import { FormFieldAtomProps } from "../../atoms/FormField/FormFieldAtomTypes";

export interface PharmacyInfoSectionProps {
  title: string;
  sections: Array<{
    subtitle: string;
    inputFields: Array<UnderlinedInputFieldProps | FormFieldAtomProps>;
    formTitle: string;
  }>;
  icon: string;
  width: 362 | 500;
  isEditMode: boolean;
  control: any;
  disableAccountNumber: boolean;
}

export const PharmacyInfoSection: React.FC<PharmacyInfoSectionProps> = (
  props: PharmacyInfoSectionProps
) => {
  const { sections, title, icon, width, isEditMode = false, control, disableAccountNumber } = props;

  const classes = useStyles(props);
  const loadSections = () => {
    return sections.map((section, i) => {
      const updatedFields = section.inputFields.map((field) => {
        if ('controllingName' in field && field.controllingName === "accountNumber") {
          return { ...field, disabled: disableAccountNumber };
        }
        return field;
      });
      return (
        <Paper key={i} elevation={0}>
          <UnderlinedInputFieldSection
            isEditMode={isEditMode}
            control={control}
            fields={updatedFields}
            title={section.subtitle}
            formTitle={section.formTitle}
            disableAccountNumber={disableAccountNumber}
          />
        </Paper>
      );
    });
  };
  return (
    <Box width={"100%"}>
      <Paper elevation={0}>
        {title ? (
          <div className={classes.tag}>
            <PharmacyInfoTag width={width} icon={icon} text={title} />
          </div>
        ) : (
          ""
        )}
        {loadSections()}
        <br />
      </Paper>
    </Box>
  );
};
