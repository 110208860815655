import {
  eachDayOfInterval,
  getDay,
  startOfMonth,
  addDays,
} from "date-fns";

interface Holiday {
  month: number;
  day: number;
}

interface SpecialHoliday {
  month: number;
  weekday: number;
  week: number;
}

const holidays: Holiday[] = [
  { month: 1, day: 1 }, // New Year's Day
  { month: 6, day: 19 }, // Juneteenth
  { month: 7, day: 4 }, // Independence Day
  { month: 11, day: 11 }, // Veterans Day
  { month: 12, day: 25 }, // Christmas Day
];

const specialHolidays: SpecialHoliday[] = [
  { month: 1, weekday: 1, week: 2 }, // Martin Luther King Jr. Day (third Monday in January)
  { month: 2, weekday: 1, week: 2 }, // Presidents' Day (third Monday in February)
  { month: 9, weekday: 1, week: 0 }, // Labor Day (first Monday in September)
  { month: 10, weekday: 1, week: 1 }, // Columbus Day (second Monday in October)
  { month: 11, weekday: 4, week: 3 }, // Thanksgiving Day (fourth Thursday in November)
];

// Memorial Day (last Monday in May)
const isLastMondayOfMay = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDay();
  if (month !== 5 || day !== 1) return false;

  const nextMonday = new Date(date);
  nextMonday.setDate(date.getDate() + 7);
  return nextMonday.getMonth() !== 4;
};

const isSpecificWeekdayOfMonth = (
  date: Date,
  weekday: number,
  week: number,
  month: number
) => {
  const targetMonth = date.getMonth() + 1;
  const targetDay = date.getDay();
  const targetDate = date.getDate();

  if (targetMonth !== month || targetDay !== weekday) {
    return false;
  }
  const start = startOfMonth(date);
  const daysInMonth = eachDayOfInterval({ start, end: date });
  let weekdayCount = 0;

  for (const d of daysInMonth) {
    if (getDay(d) === weekday) {
      weekdayCount++;
      if (weekdayCount === week + 1 && d.getDate() === targetDate) {
        return true;
      }
    }
  }
  return false;
};

const isHoliday = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return holidays.some(
    (holiday) => holiday.month === month && holiday.day === day
  );
};

const isDisabledDate = (date: Date) => {
  return (
    specialHolidays.some(({ month, weekday, week }) =>
      isSpecificWeekdayOfMonth(date, weekday, week, month)
    ) || isLastMondayOfMay(date)
  );
};

const getNextEnabledDays = (startDate: Date): Date[] => {
  let enabledDays: Date[] = [];
  let currentDate = addDays(startDate, 2);

  while (enabledDays.length < 7) {
    const day = currentDate.getDay();
    const isWeekend = day === 0 || day === 6;
    const isHolidayOrSpecialDay =
      isHoliday(currentDate) || isDisabledDate(currentDate);

    if (!isWeekend && !isHolidayOrSpecialDay) {
      enabledDays.push(new Date(currentDate));
    }
    currentDate = addDays(currentDate, 1);
  }
  return enabledDays;
};

export const nextDays = getNextEnabledDays(new Date());

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
