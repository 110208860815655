import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularStaticProps {
  numerator: number;
  denominator: number;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic(props: CircularStaticProps) {
  const { numerator, denominator } = props;
  const [progress, setProgress] = React.useState<number | null>(0);

  const calculateProgress = (numerator: number, denominator: number) => {
    return (numerator / denominator) * 100;
  };

  React.useEffect(() => {
    let currentNumerator = numerator;
    setProgress(calculateProgress(currentNumerator, denominator));
  }, [numerator, denominator]);

  React.useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(null);
      }, 5000);
    }
  }, [progress]);

  return (
    <>
      {progress !== null ? (
        <CircularProgressWithLabel value={progress} />
      ) : null}
    </>
  );
}