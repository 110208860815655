import axios from "axios";
export default axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    // baseURL: "http://localhost:8080/rxmax",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Referrer-Policy": "origin",
        "Access-Control-Allow-Credentials": "true",
        "Authorization": localStorage.getItem("token")
    }
});

export const axiosCallUS = axios.create({
    baseURL: "http://18.216.169.216/rxmax",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Referrer-Policy": "origin",
    }
});