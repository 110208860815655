import React from "react";
import { TextField } from "@material-ui/core";
import "./textFieldStyles.ts";
import { useStyles, Container } from "./textFieldStyles";
import { Controller } from "react-hook-form";

export interface TextFieldProps {
  control: any;
  controlingName: string;
  placeHolder: string;
  type?: string;
  rules?: any;
  minDate?: string;
  //width?: 500 | 199,
  version: TextFieldVersion;
  disabled?: boolean;
  helperText?: string;
  isMultiline?: boolean;
  maxRows?: number;
  hintText?: string;
  value?: string;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLDivElement>,
    controlingName: string
  ) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  // defaultValue?: string;
}

export enum TextFieldVersion {
  AUTO,
  MANUAL,
}

const TextFieldM: React.FC<TextFieldProps> = ({
  type,
  placeHolder,
  version,
  controlingName,
  control,
  disabled,
  children,
  rules,
  minDate,
  helperText,
  isMultiline,
  maxRows,
  hintText,
  value,
  onKeyDown,
  // defaultValue,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <Container>
      <Controller
        control={control}
        name={controlingName}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          // Material UI TextField already supports
          // `value` and `onChange`
          <div className={classes.wrapper}>
            <TextField
              className={classes.root}
              variant="outlined"
              InputProps={{
                className: classes.input,
                inputProps: {
                  min: minDate,
                },
                inputRef: props.inputRef,
              }}
              {...props}
              {...field}
              placeholder={placeHolder}
              // value={value}
              // defaultValue={defaultValue}
              type={type}
              disabled={disabled}
              label={helperText}
              InputLabelProps={{
                shrink: true,
              }}
              multiline={isMultiline ? true : false}
              maxRows={isMultiline ? maxRows : 1}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e, controlingName);
                }
              }}
            >
              {children}
            </TextField>
            <span className={classes.hintText}>{hintText && hintText}</span>
            <span className={classes.errorMessage}>
              {error && error.message}
            </span>
          </div>
        )}
      />
    </Container>
  );
};

export default TextFieldM;
