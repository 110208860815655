import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { Container, TitleWrapper } from "./UploadDrugsInfoStyles";
import { useForm } from "react-hook-form";
import { TextBox } from "../../atoms/Text Box/TextBox";
import UploadFileArea from "../../atoms/UploadFileArea/UploadFileArea";
import useApiService from "../../utilities/useApiService";
import { toastFail } from "../../utilities/toast";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { mediumButton } from "../../utilities/style";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import CircularStatic from "../../atoms/UploadProgress/UploadProgress";
import axios from "../../network/ApiConfig";
import { useJobStatus } from "../../context/JobStatusContext";

export enum JobStatus {
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Failed = "FAILED",
}

export interface UploadActualPricesProps {}

const UploadActualPrices: React.FC<UploadActualPricesProps> = () => {
  const { postMethod } = useApiService();
  const { control, handleSubmit, setValue, register } = useForm();
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const loaderDispatch = useLoaderDispatch();
  const [loaded, setLoaded] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { jobStatus, setJobStatus } = useJobStatus();
  const { loading } = useLoaderState();
  register("file", { required: true });

  const onDrop = (files: any) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);
    setValue("file", uploadedFile);
  };

  const handleClick = handleSubmit((data) => {
    if (!file) {
      showError("Choose a file to upload first!");
    } else {
      const formData = new FormData();
      formData.append("file", data.file);

      mutate(formData);
    }
  });

  const checkJobStatus = async (jobId: string) => {
    if (!jobId) {
      return;
    }
    await axios
      .get(`/actual-prices/csv/upload/${jobId}/status`)
      .then((response) => {
        setJobStatus(response.data);
        if (response.data === JobStatus.InProgress)
          setTimeout(() => {
            checkJobStatus(jobId);
          }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadFile = async (data: any) => {
    startLoading(loaderDispatch);
    const response = await postMethod(`/actual-prices/csv/upload`, data, {
      onUploadProgress(progressEvent: ProgressEvent) {
        const { loaded, total } = progressEvent;
        setLoaded(loaded);
        setTotal(total);
      },
    });
    stopLoading(loaderDispatch);
    return response;
  };

  const { mutate } = useMutation(uploadFile, {
    onSuccess: (response) => {
      if (response.response && response.response.status === 415) {
        showError("Incorrect file type, please choose a file of type CSV!");
      } else if (response.response && response.response.status >= 400) {
        toastFail("Uploading CSV failed.");
      } else {
        const jobId = response.data;
        setJobStatus(JobStatus.InProgress);
        checkJobStatus(jobId);
      }
      setFile(null);
    },
  });

  const showError = (errorMessage: string) => {
    setError(true);
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const renderUploadAreaOrLoader = () => {
    let uploadFileAreaForm = (
      <form>
        <UploadFileArea
          control={control}
          controllingName="file"
          onDrop={onDrop}
          file={file}
          error={error}
          errorMessage={errorMessage}
          fileSupported=".csv"
        />
      </form>
    );

    if (loading) {
      return (
        <>
          <TextBox
            bodyFontSize={16}
            paragraphs={[`Importing actual prices from ${file.path}`]}
          />
          <div>
            <CircularStatic numerator={loaded} denominator={total} />
          </div>
        </>
      );
    } else if (jobStatus === null) {
      return uploadFileAreaForm;
    } else if (jobStatus === JobStatus.InProgress) {
      return (
        <>
          <TextBox
            bodyFontSize={16}
            paragraphs={["Importing actual prices from the CSV file..."]}
          />
          <div>
            <CircularStatic numerator={loaded} denominator={total} />
          </div>
        </>
      );
    } else if (jobStatus === JobStatus.Completed) {
      return (
        <>
          <TextBox
            bodyFontSize={16}
            paragraphs={["Importing actual prices from the CSV file is complete."]}
          />
          {uploadFileAreaForm}
        </>
      );
    } else if (jobStatus === JobStatus.Failed) {
      return (
        <>
          <TextBox
            bodyFontSize={16}
            paragraphs={["Importing actual prices from the CSV file has failed."]}
          />
          {uploadFileAreaForm}
        </>
      );
    }
  };

  return (
    <Container>
      <TitleWrapper>
        <TextBox
          headerFontSize={24}
          title={"Upload Actual Prices"}
          titleColor={"#A61C14"}
        />
      </TitleWrapper>
      <TextBox
        bodyFontSize={18}
        paragraphs={[
          "Upload a CSV file that contains actual price information to import into the system",
        ]}
      />
      {renderUploadAreaOrLoader()}
      <CallToActionButton
        height={"80px"}
        text="Upload"
        width={mediumButton.width}
        onClick={handleClick}
      />
    </Container>
  );
};

export default UploadActualPrices;
