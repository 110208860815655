export enum UserRole {
   User = "USER",
   Admin = "ADMIN",
   WholesalerUser = "WHOLESALER",
   Warehouse = "WAREHOUSE",
   FieldProcessor = "FieldProcessor"
}

export enum ReturnRequestServiceType {
   FullService = "FULL_SERVICE",
   ExpressService = "EXPRESS_SERVICE"
}

export enum ReturnRequestStatus {
   Preparing = "Preparing",
   Canceled = "Canceled",
   InTransit = "InTransit",
   Received = "Received",
   Processing = "Processing",
   Processed = "Processed",
   Shipped = "Shipped",
   WaitingForManufacturers = "WaitingForManufacturers",
   PaidDirectly = "PaidDirectly",
   PaidByManufacturer = "PaidByManufacturer",
   PaidThroughWholesaler = "PaidThroughWholesaler",
   Closed = "Closed"
}
