import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { largeFontSize } from "../../utilities/style";
import { TableOrganismProps } from "./TableOrganism";

export interface TableMoleculeStylesProps {}
export const useStyles = makeStyles<Theme, TableOrganismProps>((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableBody: {
      "& .MuiTableCell-root": {
        padding: "15px",
        fontSize: "15px",
      },
      "& .MuiTableCell-root:first-child": {
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-root:nth-child(7), & .MuiTableCell-root:nth-child(8), & .MuiTableCell-root:nth-child(9)":
        {
          padding: "5px",
        },
    },
  })
);

export const LabelWrapper = styled.div`
  .label {
    font-size: ${largeFontSize.fontSize}px;
  }
`;

export const Wrapper = styled.div`
  .MuiPaper-rounded {
    border-radius: 10px;
  }
  .MuiTableRow-head > th {
    border-bottom: 0px;
  }
  .MuiTableRow-head > th:first-child {
    border-bottom-left-radius: 10px;
  }
  .MuiTableRow-head > th:last-child {
    border-bottom-right-radius: 10px;
  }
  .MuiTableRow-head > th:last-child {
    border-right: 0px;
  }
`;
