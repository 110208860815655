import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { ItemsTable } from "../../network/models/TableInterfaces";
import {
  createInfoCell,
  createClickableCell,
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";

export const createItemsRows = (
  data: Array<ItemsTable>,
  mutate: any,
  returnRequestStatus: any,
  setEditingItem: (item: ItemsTable | null) => void
) => {
  const rows: Array<TableRowProps> = [];

  data.forEach((entry) => {
    const row: TableRowProps = createRow(
      createInfoCell(entry.ndc), //NDC
      createInfoCell(entry.description), //Description
      createInfoCell(entry.manufacturer), //Manufacturer
      createInfoCell(entry.strength), //Strength
      createInfoCell(entry.dosage), //Dosage
      createInfoCell(entry.lotNumber), //Lot Number
      createInfoCell(entry.expirationDate), //Expiration Date
      createInfoCell(entry.packageSize), //Pkg Size
      createInfoCell(entry.controlledSubstanceCode), //Controlled Substance Code
      createInfoCell(entry.fullQuantity.toString(10)), //Full Quantity
      createInfoCell(entry.partialQuantity.toString(10)), //Partial Quantity
      createInfoCell(parseFloat(entry.extendedActualPrice) !== 0
        ? parseFloat(entry.extendedActualPrice).toFixed(2)
        : parseFloat(entry.extendedPrice).toFixed(2)), //Extended Price
      createInfoCell(parseFloat(entry.extendedUnitActualPrice) !== 0
        ? parseFloat(entry.extendedUnitActualPrice).toFixed(2)
        : parseFloat(entry.extendedUnitPrice).toFixed(2)), //Extended Unit Price
      returnRequestStatus === "DRAFT" || returnRequestStatus !== "SENT"
        ? createClickableCell(() => mutate({ id: entry.id }), "Remove")
        : "",
      createClickableCell(() => setEditingItem(entry), "Edit")
    );

    rows.push(row);
  });
  return rows;
};
