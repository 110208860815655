import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import IPage from "../../config/interfaces/page";
import { PharmacyInfoSection } from "../../organisms/pharmacyInfoSection/PharmacyInfoSection";
import { getAcc, getSections, getWholesalersForm } from "./PharmacyPageProfileData";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import {
  Wrapper,
  CardWrapper,
  ButtonsWrapper,
  ButtonWrapper,
  EditIconWrapper
} from "./PharmacyProfilePageStyle";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { useMemo } from "react";
import useApiService from "../../utilities/useApiService";
import { createReturnRequestApi } from "../../network/Services";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { largeButton } from "../../utilities/style";
import { ReturnRequestServiceType, UserRole } from "../../utilities/Enums";
import { isAdmin, isRoleIncluded, isUserRoleNotEqual } from '../../utilities/checkUserRole';
import { useAuthState } from "../../context/AuthContext";
import { ICreatePharmacyFullInfo, IPharmacyWholesaler, IWholesalerInfo } from './../../network/models/PharmacyInterfaces';
import { FormContainer } from "../createAccount/CreateAccountStyles";
import AccordionAtom from "../../atoms/Accordion/AccordionAtom";
import { IWholesaler } from "../../network/models/Interfaces";
import { SmallBlueButtonAtom } from "../../atoms/SmallBlueButton/SmallBlueButtonAtom";
import { SelectOption } from './../../utilities/SelectOptions';
import EditIcon from "@material-ui/icons/Edit";

export interface IRearrangedPharmacyWholesalers {
  wholesalerIds: Array<number>;
  addresses: Array<string>;
  cities: Array<string>;
  states: Array<string>;
  zipCodes: Array<string>;
  primaryVals: Array<boolean>;
}

export interface PharmacyProfilePageProps { }

export const PharmacyProfilePage: React.FC<
  PharmacyProfilePageProps & RouteComponentProps<any> & IPage
> = (props) => {
  const { user } = useAuthState();
  const userRole = user!.role;
  const { getMethod, putMethod, postMethod } = useApiService();

  const [pageChapters, setPageChapters] = useState<any[]>([]);
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [wholesalerList, setWholesalerList] = useState<Array<IWholesaler>>([]);
  const [wholesalerSelectOptions, setWholesalerSelectOptions] = useState<Array<SelectOption>>([]);
  const [addAdditional, setAddAdditional] = useState<Array<JSX.Element>>([]);
  const [countAdditional, setCountAdditional] = useState<number>(0);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmMakePrimary, setOpenConfirmMakePrimary] = useState(false);
  const [errorArr, setErrorArr] = React.useState<number>(1);
  const [chosenWholesalersInfo, setChosenWholesalersInfo] = useState<IWholesalerInfo>({} as IWholesalerInfo);
  const [newAddedWholeSaler, setNewAddedWholeSaler] = useState<boolean>(false);
  const [isPrimaryWholeSaler, setIsPrimaryWholeSaler] = useState<string>("");
  const [confirmToBePrimary, setConfirmToBePrimary] = useState<string>("");
  
  const [rearrangedPharmacyWholesalers, setRearrangedPharmacyWholesalers] = useState<IRearrangedPharmacyWholesalers>({
    wholesalerIds: [],
    addresses: [],
    cities: [],
    states: [],
    zipCodes: [],
    primaryVals: [],
  })
  const [accts, setAccts] = useState<any>(undefined)


  let history = useHistory();
  const params: any = useParams();
  const getWholesalers = async () => {
    startLoading(loaderDispatch);
    let response;
    response = await getMethod(`/wholesalers`)
    stopLoading(loaderDispatch);
    return response.data;
  }
  const onSuccessfulWholesalersFetching = (wholesalers: Array<IWholesaler>) => {
    setWholesalerList([...wholesalers])
    setWholesalerSelectOptions(formulateWholesalerSelectOptions(wholesalers))
    refetch()
  }
  const { data: wholesalers } = useQuery('wholesalers', getWholesalers, {
    onSuccess: onSuccessfulWholesalersFetching
  });

  const formulateWholesalerSelectOptions = (wholesalers: Array<IWholesaler>) => {
    return wholesalers.map((wholesaler: IWholesaler) => ({
      value: wholesaler.id?.toString(),
      label: wholesaler.name
    }))
  }

  async function fetchMyAPI() {
    startLoading(loaderDispatch);
    setPageChapters([]);
    let response;

    response = await getMethod(`/pharmacies/${params.pharmacyId}/full`);

    let data = response.data;
    stopLoading(loaderDispatch);
    return data;
  }
  const { data, refetch } = useQuery("pharmacyInfo", fetchMyAPI, {
    onSuccess: (fullPharmacy) => {
      const { pharmacy } = fullPharmacy;
          
      let rearrangedWholesalers: IRearrangedPharmacyWholesalers = {
        wholesalerIds: [],
        addresses: [],
        cities: [],
        states: [],
        zipCodes: [],
        primaryVals: [],
      };
      if(pharmacy.wholesalersLinks !== null && pharmacy.wholesalersLinks !== undefined) {
        pharmacy.wholesalersLinks.forEach((wholesaler: IPharmacyWholesaler) => {
          rearrangedWholesalers.wholesalerIds.push(wholesaler.wholesalerId)
          rearrangedWholesalers.addresses.push(wholesaler.address)
          rearrangedWholesalers.cities.push(wholesaler.city)
          rearrangedWholesalers.states.push(wholesaler.state)
          rearrangedWholesalers.zipCodes.push(wholesaler.zipCode)
          rearrangedWholesalers.primaryVals.push(wholesaler.primary)
        })
        setRearrangedPharmacyWholesalers({ ...rearrangedWholesalers })

      }
      setPageChapters([...getSections(fullPharmacy)])
    }
  });

  const { handleSubmit, control, setValue, watch, getValues, reset, formState: { errors } } = useForm(
    {
      defaultValues: {
        pharmacy: {
          reimbursementType: data && data.pharmacy.reimbursementType,
          directDepositInfo: data && data.pharmacy.directDepositInfo,
        },
        wholeSalerInfo: {
          id: rearrangedPharmacyWholesalers?.wholesalerIds,
          address: rearrangedPharmacyWholesalers?.addresses,
          city: rearrangedPharmacyWholesalers?.cities,
          zip: rearrangedPharmacyWholesalers?.zipCodes,
          state: rearrangedPharmacyWholesalers?.states,
          isPrimary: rearrangedPharmacyWholesalers?.primaryVals
        }
      },
  });


  useEffect(() => {
    if (data && data.pharmacy) {
      setValue("pharmacy.reimbursementType", data.pharmacy.reimbursementType);
      if (data && data.pharmacy.directDepositInfo) {
        setValue(
          "pharmacy.directDepositInfo.aceNumber",
          data.pharmacy.directDepositInfo.aceNumber
        );
        setValue(
          "pharmacy.directDepositInfo.routingNumber",
          data.pharmacy.directDepositInfo.routingNumber
        );
        setValue(
          "pharmacy.directDepositInfo.bankName",
          data.pharmacy.directDepositInfo.bankName
        );
        setValue(
          "pharmacy.directDepositInfo.name",
          data.pharmacy.directDepositInfo.name
        );
      }
    }
  }, [data, setValue]);

  const reimbursementType = watch("pharmacy.reimbursementType");

  const  convertPharmacyData = (updatedData: any) => {
    const { pharmacy } = updatedData;
  
    const convertedData: ICreatePharmacyFullInfo = {
      pharmacy: {
        ...updatedData.pharamcy,
        wholesalersLinks: updatedData.wholeSalerInfo.id.map((id: string, index: number) => ({
          wholesalerId: updatedData.wholeSalerInfo.id[index] || rearrangedPharmacyWholesalers.wholesalerIds[index],
          pharmacyId: data.pharmacy.id,
          address: updatedData.wholeSalerInfo.address[index] || rearrangedPharmacyWholesalers.addresses[index],
          city: updatedData.wholeSalerInfo.city[index] || rearrangedPharmacyWholesalers.cities[index],
          state: updatedData.wholeSalerInfo.state[index] || rearrangedPharmacyWholesalers.states[index],
          zipCode: updatedData.wholeSalerInfo.zip[index] || rearrangedPharmacyWholesalers.zipCodes[index],
          primary: isPrimaryWholeSaler === `AdditionalWholeSaler${index}` ? true : false
        })),
      },
      pharmacyContactInfo: {

        ...updatedData.pharmacyContactInfo,
        id: data.pharmacy.id,
        createdAt: data.pharmacyContactInfo.createdAt,
        updatedAt: data.pharmacyContactInfo.updatedAt,
      },
      pharmacyCompanyAddressInfo: {
        ...updatedData.pharmacyCompanyAddressInfo,
        address2: updatedData.pharmacyCompanyAddressInfo.address2 || data.pharmacyCompanyAddressInfo.address2,
        id: data.pharmacy.id,
        createdAt: data.pharmacyCompanyAddressInfo.createdAt,
        updatedAt: data.pharmacyCompanyAddressInfo.updatedAt,
      },
      pharmacyMailingAddressInfo: {
        ...updatedData.pharmacyMailingAddressInfo,
        address2: updatedData.pharmacyMailingAddressInfo.address2 || data.pharmacyMailingAddressInfo.address2,
        id: data.pharmacy.id,
        createdAt: data.pharmacyMailingAddressInfo.createdAt,
        updatedAt: data.pharmacyMailingAddressInfo.updatedAt,
      },
    };
  
    return convertedData;
  }

  const onSubmit = handleSubmit(async (formData) => {
    handleAdditionalWholesaler();
    const convertedData = convertPharmacyData(formData);

    const updatedData = {
      pharmacy: {
        ...formData.pharmacy,
        id: data.pharmacy.id,
        user: data.pharmacy.user,
        enabled: data.pharmacy.enabled,
        directDepositInfo: data.pharmacy.directDepositInfo,
        wholesalersLinks: convertedData.pharmacy.wholesalersLinks,
      },
      pharmacyCompanyAddressInfo: convertedData.pharmacyCompanyAddressInfo,
      pharmacyContactInfo: convertedData.pharmacyContactInfo,
      pharmacyMailingAddressInfo: convertedData.pharmacyMailingAddressInfo,
      promoCode: data.promoCode
    };
  
    updatePharmacy(updatedData);
  })

  
 

  const updatePharmacyApi = async (data: any) => {
    startLoading(loaderDispatch);
    const updateObject = {
      pharmacyFullInfo: data,
    };
    if (isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)) {
      await putMethod(`/admin/pharmacies/${params.pharmacyId}`, data);
    }
    setIsEditMode(false);
    stopLoading(loaderDispatch);
  }
  const { mutate: updatePharmacy } = useMutation(updatePharmacyApi, {
    onSuccess: () => {
      refetch()
      window.location.reload();         //Not a good practice, needs to be changed
    }
  })

  const handleClick = () => {
    if (isRoleIncluded([UserRole.User], userRole)) {
      history.push(`/pharmacies/${params.pharmacyId}/returnrequest/create`);
    } else {
      history.push(`/admin/pharmacies/${params.pharmacyId}/returnrequests/add`);
    }
  }

  const handleCancel = () => {
    setPageChapters([]);
    refetch();
    setIsEditMode(false);
    reset();
  };

  const handleAdditionalWholesaler = () => {
    let wholeSaler = getValues().wholeSalerInfo as unknown as IWholesalerInfo;
    let infoId = Object.values(wholeSaler.id);
    let infoCity = Object.values(wholeSaler.city)
    let infoState = Object.values(wholeSaler.state)
    let infoZip = Object.values(wholeSaler.zip)
    let infoAddress = Object.values(wholeSaler.address)

    let infoIsPrimary = new Array(infoId.length).fill(false)
    addAdditional.map((wholeSaler, index) => {
      if (wholeSaler.props.name === isPrimaryWholeSaler) {
        infoIsPrimary[index] = true
      }
    })

    let finalInfoAfterDeleting: IWholesalerInfo =
      {
        id: infoId,
        city: infoCity,
        state: infoState,
        zip: infoZip,
        isPrimary: infoIsPrimary,
        address: infoAddress
      } as unknown as IWholesalerInfo
    setChosenWholesalersInfo(finalInfoAfterDeleting)
  }

  useEffect(() => {
    setAccts(getAcc(rearrangedPharmacyWholesalers, wholesalerSelectOptions, wholesalerList))
  }, [rearrangedPharmacyWholesalers])

  useEffect(() => {
    if (countAdditional > addAdditional.length - 1 && countAdditional != 0 && newAddedWholeSaler) {
      const newAdditionalWholesaler: JSX.Element = (
        <AccordionAtom
          subTitle={(isPrimaryWholeSaler == `AdditionalWholeSaler${countAdditional}`) ? "Primary" : isEditMode ? <SmallBlueButtonAtom text={"Make primary"} onClick={() => handleMakePrimary(`AdditionalWholeSaler${countAdditional}`)} /> : ""}
          key={`AdditionalWholeSaler${countAdditional}`}
          name={`AdditionalWholeSaler${countAdditional}`}
          id={`AdditionalWholeSaler${countAdditional}`}
          title="Additional WholeSalers"
          body={
            <FormMolecule
              key={`AdditionalWholeSaler${countAdditional}`}
              control={control as any}
              list={getWholesalersForm(countAdditional, rearrangedPharmacyWholesalers, wholesalerSelectOptions, wholesalerList)!}
            />
          }
        />
      )
      setAddAdditional(addAdditional.concat(newAdditionalWholesaler))
    }
  }, [countAdditional, rearrangedPharmacyWholesalers])

  const handleWholSalerForms = () => {
    setNewAddedWholeSaler(true)
    setCountAdditional(countAdditional + 1)
  }

  const handleMakePrimary = (name: string) => {
    setOpenConfirmMakePrimary(true)
    setConfirmToBePrimary(name)
    setIsPrimaryWholeSaler(name)
  }

  useEffect(() => {
    const newAddAdditional = addAdditional.map((wholeSaler, index) => {
      if (isPrimaryWholeSaler === wholeSaler.props.name) {
        return { ...wholeSaler, props: { ...wholeSaler.props, subTitle: "Primary", deleteIcon: false } }
      }
      return { ...wholeSaler, props: { ...wholeSaler.props, subTitle: isEditMode ? <SmallBlueButtonAtom text={"Make primary"} onClick={() => handleMakePrimary(wholeSaler.props.name)} /> : "" } }
    })
    setAddAdditional(newAddAdditional)

  }, [isPrimaryWholeSaler])

  useEffect(() => {
    if (accts) {
      console.log("Accts: ", accts)
      let wholeSalersACC: any = []
      if (pageChapters[pageChapters.length - 1]) {
        if (countAdditional == 0)
          setCountAdditional(accts.length - 1)
        wholeSalersACC = accts.map((list: any, index: any) => {
          let name: any = '';
          if (wholesalerList) {
            name = list[0][0].placeHolder
          }
          const isPrimary: boolean = rearrangedPharmacyWholesalers!.primaryVals[index]
          if (isPrimary) setIsPrimaryWholeSaler(`AdditionalWholeSaler${index}`)
          return (
            <AccordionAtom
              openClosed={true}
              subTitle={(isPrimaryWholeSaler == `AdditionalWholeSaler${index}`) ? "Primary" : isEditMode ? <SmallBlueButtonAtom text={"Make primary"} onClick={() => handleMakePrimary(`AdditionalWholeSaler${index}`)} /> : ""}
              name={`AdditionalWholeSaler${index}`}
              key={`AdditionalWholeSaler${index}`}
              title={!isEditMode ? name : 'Edit wholesaler'}
              body={<FormMolecule disabled={!isEditMode} control={control as any} list={list} />}
            />
          )
        })
        setAddAdditional(wholeSalersACC)
      }
    }
  }, [pageChapters, isEditMode, accts]);

  const loadSections = useMemo(() => {
    const directDeposit = [
      [
        {
          controllingName: "pharmacy.directDepositInfo.aceNumber",
          placeHolder: "Account Number",
          disabled: !isEditMode,
          rules: {
            required: "this field is required",
          },
        },
        {
          controllingName: "pharmacy.directDepositInfo.routingNumber",
          placeHolder: "Routing Number",
          disabled: !isEditMode,
          rules: {
            required: "this field is required",
          },
        },
      ],
      [
        {
          controllingName: "pharmacy.directDepositInfo.bankName",
          placeHolder: "Bank Name",
          disabled: !isEditMode,
          rules: {
            required: "this field is required",
          },
        },
        {
          controllingName: "pharmacy.directDepositInfo.name",
          placeHolder: "Name",
          disabled: !isEditMode,
          rules: {
            required: "this field is required",
          },
        },
      ],
    ];
    const renderWholesalersChapter = (currentChapterIndex: number, wholesalerChapterIndex: number) => {
      if (currentChapterIndex === wholesalerChapterIndex) {
        return (
          <FormContainer>
            {addAdditional}
            {
              isEditMode && <AccordionAtom name={"Add Additional Wholesaler"} onClickEvent={handleWholSalerForms} alwaysOpened={true} subTitle={""} title={"Add Additional Wholesaler"} body={false} />
            }
          </FormContainer>
        )
      }
    }

    return pageChapters.map((chapter, i) => {
      return (
        <Wrapper key={i}>
          <PharmacyInfoSection
            width={chapter.width}
            icon={chapter.icon}
            sections={chapter.sections}
            title={chapter.title}
            isEditMode={isEditMode}
            control={control}
            disableAccountNumber={isUserRoleNotEqual(user?.role, UserRole.Admin)}
          />
          {i === 0 && (
            <Controller
              control={control}
              name={"pharmacy.reimbursementType"}
              defaultValue={localStorage.getItem('radioGroupValue') || "CHEQUE_REQUEST"}
              render={({ field, fieldState: { error } }) => (
                <RadioGroup aria-label="Reimbursement Type" {...field}>
                  <FormControlLabel
                    disabled={!isEditMode}
                    value="CHEQUE_REQUEST"
                    control={<Radio />}
                    label="Check Request"
                  />
                  <FormControlLabel
                    disabled={!isEditMode}
                    value="DIRECT_DEPOSIT"
                    control={<Radio />}
                    label="Direct Deposit"
                />
              </RadioGroup>
            )}
          />
          )}
          {i === 0 && reimbursementType === "DIRECT_DEPOSIT" && (
            <FormMolecule control={control as any} list={directDeposit} />
          )}
          {renderWholesalersChapter(i, pageChapters.length - 1)}
        </Wrapper>
      );
    });
  }, [pageChapters, control, isEditMode, reimbursementType, wholesalerList, accts, addAdditional, openConfirmDelete, countAdditional, openConfirmMakePrimary]);

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <div style={{ marginBottom: isAdmin(userRole) ? 20 : 40 }}>
        {history.location.pathname.indexOf("/admin/all/pharmacies/returns") ===
          -1 && (
            <CallToActionButton
              height={"80px"}
              text="Create Return Request"
              width={largeButton.width}
              onClick={handleClick}
              disabled={!data?.pharmacy?.enabled}
            />
          )}
      </div>

      {isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole) && (
        <EditIconWrapper onClick={() => setIsEditMode(true)}>
          <EditIcon />
        </EditIconWrapper>
      )}

      <form onSubmit={onSubmit}>
        {history.location.state === "admin" ? (
          <CardWrapper>
            {loadSections}
            {isEditMode && (
              <ButtonsWrapper>
                <ButtonWrapper>
                  <BigRedButton
                    version={ButtonVersion.SMALL}
                    text={"Update"}
                    type="submit"
                    onClick={() => { setErrorArr(errorArr + 1); onSubmit() }}
                  />
                </ButtonWrapper>
                <ButtonWrapper>
                  <TextButton
                    text={"Cancel"}
                    version={TextButtonVersion.PRIMARY}
                    onClick={handleCancel}
                  ></TextButton>
                </ButtonWrapper>
              </ButtonsWrapper>
            )}
          </CardWrapper>
        ) : (
          <>
            {loadSections}
            {isEditMode && (
              <ButtonsWrapper>
                <ButtonWrapper>
                  <BigRedButton
                    version={ButtonVersion.SMALL}
                    text={"Update"}
                    type="submit"
                    onClick={() => { setErrorArr(errorArr + 1); onSubmit() }}
                  />
                </ButtonWrapper>
                <ButtonWrapper>
                  <TextButton
                    text={"Cancel"}
                    version={TextButtonVersion.PRIMARY}
                    onClick={handleCancel}
                  ></TextButton>
                </ButtonWrapper>
              </ButtonsWrapper>
            )}
          </>
        )}
      </form>
    </Container>
  );
};